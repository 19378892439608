<template>
  <div id="user-add">
    <vx-card class="pt-5 pb-5">
      <upload-avatar :user="user" @update:avatar="avatarUpdate" @uploaded:avatar="avatarUploaded"/>
      <user-form :user="user" @new:user="userNew" @update:user:firstname="firstnameUpdate" @update:user:lastname="lastnameUpdate" />
    </vx-card>
  </div>
</template>

<script>
import UploadAvatar from '@/views/apps/user/user-form/UploadAvatar'
import UserForm from '@/views/apps/user/user-form/UserForm'

// Store Module
import moduleUserManagementMixin from '@/store/user-management/moduleUserManagementMixin'

export default {
  components: {
    UploadAvatar,
    UserForm
  },
  mixins: [moduleUserManagementMixin],
  data () {
    return {
      user: {
        groups: []
      },
      isModified: false,
      fileAvatar: null
    }
  },
  methods: {
    avatarUpdate (file)  {
      this.fileAvatar = file
      if (file) {
        const reader = new FileReader()
        reader.onload = e => {
          this.$set(this.user, 'tempAvatar', e.target.result)
          this.$set(this.user, 'fileAvatar', this.fileAvatar)
        }
        reader.readAsDataURL(file)
      } else {
        this.$set(this.user, 'tempAvatar', null)
        this.$set(this.user, 'fileAvatar', null)
      }
    },
    avatarUploaded (mediaObject) {
      this.isModified = true
      this.$set(this.user, 'avatar', mediaObject)
    },
    firstnameUpdate (firstname) {
      this.isModified = true
      this.$set(this.user, 'firstname', firstname)
    },
    lastnameUpdate (lastname) {
      this.isModified = true
      this.$set(this.user, 'lastname', lastname)
    },
    async userNew (user) {
      this.isModified = true
      if (this.fileAvatar) {
        const responseMediaObject = await this.$store.dispatch('mediaObject/addMediaObjectAvatar', {
          file: this.fileAvatar,
          onUploadProgress: this.onAvatarUpload
        })
        user.avatar = responseMediaObject.data['@id']
      }
      this.$store.dispatch('userManagement/addUser', user)
        .then(() => {
          this.isModified = false
          this.$router.push({'name': 'user-list'})
          this.$vs.notify({
            color: 'success',
            title: this.$t('administration.userform.form.submit.success.notify.title'),
            text: this.$t('administration.userform.form.submit.success.notify.text', { username: user.username })
          })
        })
        .catch((error) => {
          console.error(error)// eslint-disable-line no-console
          this.$vs.notify({
            color: 'danger',
            title: this.$t('administration.userform.form.submit.error.notify.title'),
            text: this.$t('administration.userform.form.submit.error.notify.text', { error: error.response.data['hydra:description'] }),
            fixed: true
          })
        })
    }
  }
}
</script>

<style scoped>

</style>
